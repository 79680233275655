function Header() {
}

Header.fn = {
    init: function () {
        Header.fn.handle.call();
    },

    handle: function() {
        const $popupOverlay = $('.popup-overlay');
        const $popupContainer = $('.popup-container');
        const $popupContent = $('.popup-content');
        const $popupClose = $('.popup-close');
        const $navsearchButton = $('.navsearch-button');
        const $navButton = $('#navButton');
        const $navItemsContainer = $('.nav-links-container');
        const $menuOverlay = $('.menu-overlay');
        const $menuCloseButton = $('.menu-close-toggle');

        $navsearchButton.on('click', function() {
            $popupOverlay.addClass('show-popup');
            $("body").css('overflow', "hidden");
        });

        $popupOverlay.on('animationend', function() {
            $popupContent.addClass('fade-in');
            $popupClose.addClass('fade-in');
            $popupContainer.addClass('show-popup');
        });

        $popupClose.on('click', function() {
            closePopup();
        });

        $navButton.on('click', function() {
            $navItemsContainer.toggleClass('mobile-menu-active');
            $menuOverlay.toggleClass('menu-overlay-active');
        });

        $menuCloseButton.on('click', function() {
            $navItemsContainer.removeClass('mobile-menu-active');
            $menuOverlay.removeClass('menu-overlay-active');
        });

        $(document).on('mouseup', function(e) {
            if (!$navItemsContainer.is(e.target) && $navItemsContainer.has(e.target).length === 0) {
                $navItemsContainer.removeClass('mobile-menu-active');
                $menuOverlay.removeClass('menu-overlay-active');
            }
        });

        $popupContainer.click(function(event) {
            var $target = $(event.target);
            if(!$target.closest('#search-form').length &&
                $popupOverlay.is(":visible")) {
                closePopup();
            }
        });

        function closePopup(){
            $popupContainer.removeClass('show-popup');
            $popupOverlay.removeClass('show-popup');
            $popupContent.removeClass('fade-in');
            $popupClose.removeClass('fade-in');
            $("body").css('overflow', "auto");
        }

        const $navItems = $('.nav-item');

        $navItems.each(function() {
            $(this).on('click', function() {
                if ($(window).width() < 1025) {
                    const $dropdown = $(this).find('.submenu-panel');
                    $dropdown.toggleClass('submenu-panel-show');
                    $dropdown.parent().toggleClass('mobile-submenu-active');
                }
            });
        });

        $(document).on('click', function(event) {
            if (!$(event.target).closest('.nav-item').length && !$(event.target).closest('.submenu-panel').length) {
                closeAllDropdowns();
            }
        });

        function closeAllDropdowns() {
            const $dropdowns = $('.submenu-panel');
            $dropdowns.each(function() {
                $(this).removeClass('submenu-panel-show');
                $(this).parent().removeClass('mobile-submenu-active');
            });
        }

    },

    rule: function () {
        $(document).ready(function () {
            Header.fn.init.call(this);
        });
    }
};

Header.fn.rule();