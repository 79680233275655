function Filter() {
}

Filter.fn = {
    init: function () {
        Filter.fn.handle.call();
    },

    handle: function() {

            // Handle click on dropdown-toggle for multi-level dropdowns
            $('.dropdown-submenu .dropdown-toggle').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var $submenu = $(this).next('.dropdown-menu');

                // Toggle submenu visibility
                if ($submenu.hasClass('show')) {
                    $submenu.removeClass('show');
                } else {
                    // Close all open submenus at the current level
                    $(this).closest('.dropdown-menu').find('.dropdown-menu').removeClass('show');

                    // Show the clicked submenu
                    $submenu.addClass('show');
                }
            });

            // Close dropdowns when clicking outside of them
            $(document).on('click', function (e) {
                if (!$(e.target).closest('.dropdown-toggle').length) {
                    $('.dropdown-menu.show').removeClass('show');
                }
            });


    },

    rule: function () {
        $(document).ready(function () {
            Filter.fn.init.call(this);
        });
    }
};

Filter.fn.rule();